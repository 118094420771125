import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json";

const markers = [
  {
    markerOffset: -15,
    name: "Afghanistan",
    coordinates: [66.2023, 33.9391],
    description:
      "In Afghanistan, mental health providers are key to providing and advocating for mental health support within their communities. PoMA makes sure they can do that by building their capacity through training and providing clinical supervision. PoMA equips them with the skills to provide holistic services reflective of culture and context, responding to the most pressing need and building resilience in vulnerable communities.",
    size: 4,
    shadowSize: 7,
  },

  {
    markerOffset: -15,
    name: "Syria",
    coordinates: [38.9968, 34.8021],
    description:
      "In Syria, graduates are well positioned to deliver community-based psychological support. PoMA has empowered them to do so by developing and delivering an accredited program in Psychosocial Support, in collaboration with a university in NW Syria, and facilitating community-based practicums; building the workforce to provide much need support in the country.",
    size: 3,
    shadowSize: 6,
    xais: 10,
    yais: -10,
  },
  {
    markerOffset: -15,
    name: "Iraq",
    coordinates: [43.6793, 33.2232],
    description:
      "In Iraq, minority Kurdish communities in the North of the country have been discriminated against and persecuted for decades, with women and girls experiencing a high level of sexual and gender-based violence. PoMA works to integrate culturally appropriate mental health support into existing Protection services, to promote women-focused recovery and resilience within this community.",
    size: 3,
    shadowSize: 7,
    yais: 15,
  },
  {
    markerOffset: -15,
    name: "Libya",
    coordinates: [17.2283, 26.3351],
    description:
      "In Libya, access to- and the types of- mental health support is limited by cultural barriers such as stigma and shame. PoMA works to integrate mental health into existing Child Protection programs, increase awareness and knowledge of mental health, and better enable families to support each other in recognizing and addressing mental health issues together.",
    size: 4,
    shadowSize: 8,
  },
  {
    markerOffset: -15,
    name: "South Africa",
    coordinates: [24.9916, -30.5595],
    description:
      "In South Africa, PoMA partnered with a government care agency to support the mental health needs of abandoned children or those removed from their families. Our efforts focused on preventing further trauma, helping them to express their emotions, and equipping them with essential coping skills for daily functioning.",
    size: 5,
    shadowSize: 10,
  },

  {
    markerOffset: -15,
    name: "Haiti",
    coordinates: [-72.2852, 18.9712],
    description:
      "In Haiti, a significant number of vulnerable families face challenges due to widespread poverty and limited access to social services, leading to a high number of children in care. PoMA worked in government centers for abandoned or undocumented children, offering psychosocial and mental health support through social interaction and social-emotional learning.",
    size: 2,
    shadowSize: 4,
  },
  {
    markerOffset: -15,
    name: "India",
    coordinates: [78.9629, 20.5937],
    description:
      "In India, gender-based violence is a highly stigmatized and pervasive issue. PoMA worked in community centers, offering safe spaces where women could seek support for all forms of violence, including psychological rehabilitation.",
    size: 6,
    shadowSize: 12,
  },
  {
    markerOffset: -15,
    name: "Switzerland",
    coordinates: [8.2275, 46.8182],
    description:
      "In Switzerland, certain communities are without access to health and social services, due to a myriad of barriers including language and lack of awareness. PoMA conducts research on mental health needs and gaps in services, to advocate for revised policy and actionable change towards a more inclusive society.",
    size: 2,
    shadowSize: 4,
    xais: -10,
    yais: -10,
  },
  {
    markerOffset: -15,
    name: "Dem. Rep. Congo",
    coordinates: [23.656, -4.038],
    description:
      "In the Democratic Republic of Congo (DRC), the widespread issues of child recruitment and torture have had devastating impacts on children, families, and communities. To address these challenges, PoMA developed inclusive programming tailored to the complex needs of affected individuals, emphasizing holistic care, combining mental health services with access to education, protection services, and community reconciliation efforts.",
    size: 6,
    shadowSize: 12,
  },
  {
    markerOffset: -15,
    name: "Poland",
    coordinates: [19.1451, 51.9194],
    description:
      "Poland has hosted a significant number of Ukrainian and third-country refugees since 2022, the majority of whom have travelled by land and settled in major cities. PoMA conducted a research study in Warsaw to gather data on the actual and perceived trafficking risks for these migrant populations.",
    size: 3,
    shadowSize: 6,
  },
  {
    markerOffset: -15,
    name: "Ukraine",
    coordinates: [31.1656, 48.3794],
    description:
      "In Ukraine and neighbouring countries, displaced Ukrainians are in dire need of mental health and psychosocial support services. PoMA's research helps to advocate for greater awareness and access to these services through policy and development channels.",
    size: 5,
    shadowSize: 10,
  },
  {
    markerOffset: -15,
    name: "Peru",
    coordinates: [-75.0152, -9.19],
    description:
      "In Peru, PoMA collaborated with non-profit organizations to develop and provide mental health support for children in care, addressing the unique challenges faced by those without parental care and stability.",
    size: 4.5,
    shadowSize: 10,
  },
  {
    markerOffset: -15,
    name: "USA",
    coordinates: [-95.7129, 37.0902],
    description:
      "In the USA, new migrants face challenges finding accommodation, accessing social services, and integrating into wider society. PoMA facilitates community-led partnership programs whereby migrant families are paired with established families to offer long-term support and guidance.",
    size: 7,
    shadowSize: 15,
    yais: -18,
  },
  {
    markerOffset: -15,
    name: "Sierra Leone",
    coordinates: [-11.7799, 8.4606],
    description:
      "PoMA conducted a comprehensive assessment of the mental health and psychosocial support (MHPSS) services available for youth and women in Sierra Leone, with a particular focus on addressing the needs of those affected by child, early, and forced marriage (CEFM) and other harmful practices. The assessment aimed to evaluate the accessibility, cultural relevance, and effectiveness of existing services in supporting survivors of these experiences.",
    size: 3.5,
    shadowSize: 8,
  },
  {
    markerOffset: -15,
    name: "France",
    coordinates: [2.2137, 46.6034],
    description:
      "PoMA’s work in the France builds on its experience supporting refugees in Switzerland, focusing on providing tailored assistance for asylum seekers and delivering therapeutic support to communities in need. These efforts emphasize culturally adapted, evidence-based programming designed to address the unique challenges faced by migrant populations, fostering resilience and promoting integration in host societies.",
    size: 4,
    shadowSize: 8,
    yais: 15,
  },
  {
    markerOffset: -15,
    name: "United Kingdom",
    coordinates: [-3.4359, 55.3781],
    description:
      "PoMA’s work in the United Kingdom builds on its experience supporting refugees in Switzerland, focusing on providing tailored assistance for asylum seekers and delivering therapeutic support to communities in need. These efforts emphasize culturally adapted, evidence-based programming designed to address the unique challenges faced by migrant populations, fostering resilience and promoting integration in host societies.",
    size: 4,
    shadowSize: 7,
    yais: -15,
  },
  {
    markerOffset: -15,
    name: "Cyprus",
    coordinates: [33.4299, 35.1264],
    description:
      "In Cyprus, PoMA worked extensively within refugee camps to support young Afghan migrants navigating the challenges of displacement and resettlement. Recognizing the profound mental health needs of these individuals, PoMA provided culturally sensitive psychosocial support to address trauma, anxiety, and stress resulting from their migration journeys.",
    size: 4,
    shadowSize: 7,
    yais: 3,
    xais: -25,
  },
  {
    markerOffset: -15,
    name: "Turkey",
    coordinates: [35.2433, 38.9637],
    description:
      "In Turkey, PoMA supported Afghan migrant communities facing post-migration stress through individualized crisis counseling, addressing acute psychological needs and fostering resilience. Additionally, PoMA conducted research on the effectiveness of Islamic Cognitive Behavioral Therapy (CBT), exploring how culturally adapted, faith-based approaches could improve mental health outcomes.",
    size: 4,
    shadowSize: 7,
    yais: -6,
  },
  {
    markerOffset: -15,
    name: "Thailand",
    coordinates: [100.9925, 15.87],
    description:
      "In Thailand, PoMA supported young individuals and communities exposed to trauma by developing and implementing socially and emotionally integrated learning programs. These programs were designed to enhance emotional resilience, promote healing, and provide practical tools for managing stress and trauma.",
    size: 5,
    shadowSize: 9,
    yais: -6,
  },
];

// Define colors for specific countries

// commented because there is one color for every country
/* const getColor = (geo) => {
  switch (geo.properties.name) {
    case "Afghanistan":
      return "#e1e1e1"; // Steel Blue for Afghanistan
    case "Syria":
      return "#e1e1e1"; // Red for Syria
    case "Iraq":
      return "#e1e1e1"; // Lime Green for Iraq
    case "Libya":
      return "#e1e1e1"; // Gold for Libya
    case "South Africa":
      return "#e1e1e1"; // Dark Orange for South Africa
    case "Switzerland":
      return "#e1e1e1"; // BlueViolet for Switzerland
    case "Dem. Rep. Congo":
      return "#e1e1e1";
    case "Poland":
      return "#e1e1e1"; // OrangeRed for Poland
    case "Ukraine":
      return "#e1e1e1"; // DodgerBlue for Ukraine
    case "Peru":
      return "#e1e1e1"; // Hot Pink for Peru
    case "United States of America":
      return "#e1e1e1"; // Sky Blue for USA
    case "Haiti":
      return "#e1e1e1"; // Use red for high-severity or urgent issues
    case "India":
      return "#e1e1e1"; // Use blue for medium-severity or focused efforts

    default:
      return "#e1e1e1"; // Light grey for all other countries
  }
}; */

export default function WhereWeWork() {
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Check for mobile size

  // Update state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        width: "90%", // Reduced width for the main container
        maxWidth: "1200px", // Maximum width for larger screens
        height: isMobile ? "auto" : "120vh",

        position: "relative",
        textAlign: "center",
        padding: "20px",
        borderRadius: "8px",
        margin: "0 auto", // Center the container
      }}
    >
      <h1
        style={{
          marginTop: "25px",
          fontSize: "35px",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        Where We Work
      </h1>

      {/* Conditionally render map or markers based on screen size */}
      {isMobile ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Mobile view - show only markers and their descriptions */}
          {markers.map(({ name, description }) => (
            <div
              key={name}
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "15px",
                margin: "10px 0",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Card shadow
                width: "80%", // Responsive width
                textAlign: "left", // Left align text
              }}
            >
              <h2 style={{ fontSize: "20px", margin: "0", color: "#FF6347" }}>
                {name}
              </h2>
              <p style={{ margin: "5px 0", color: "#666" }}>{description}</p>
            </div>
          ))}
        </div>
      ) : (
        <ComposableMap style={{ height: "100%", width: "100%" }}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={"#e1e1e1"} // Apply color based on country
                  stroke="#000"
                  strokeWidth={0.3}
                  style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                  }}
                />
              ))
            }
          </Geographies>
          {markers.map(
            ({
              name,
              coordinates,
              markerOffset,
              description,
              size,
              shadowSize,
              xais = 0,
              yais = -13,
            }) => (
              <Marker
                key={name}
                coordinates={coordinates}
                onMouseEnter={() => setHoveredMarker(name)}
                onMouseLeave={() => setHoveredMarker(null)}
              >
                {/* Shadow circle without offset */}
                <circle r={shadowSize} fill="rgb(173, 156, 188" />{" "}
                {/* Dark shadow */}
                {/* Main circle, centered */}
                <circle r={size} fill="#561f79" /> {/* Dot marker */}
                {/* Add text above the marker */}
                <text
                  x={xais}
                  y={yais} // Adjust this value to move the text up or down
                  textAnchor={"middle"} // Center the text
                  fontSize={10} // Adjust font size relative to marker size
                  fill="#000" // Text color
                  fontWeight={"bold"}
                >
                  {name}
                </text>
              </Marker>
            )
          )}
        </ComposableMap>
      )}

      {/* Tooltip */}
      {hoveredMarker && !isMobile && (
        <div
          style={{
            position: "absolute",

            top: [
              "Libya",
              "Haiti",
              "India",
              "Iraq",
              "Afghanistan",
              "DRC",
            ].includes(hoveredMarker)
              ? "65%"
              : "55%", // Adjust this value for your layout
            left: "50%", // Adjust this value for your layout
            transform: "translate(-50%, -50%)",
            padding: "5px 10px",
            backgroundColor: "#FFF",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
          }}
        >
          <h3
            style={{
              margin: "0",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#FF6347",
            }}
          >
            {hoveredMarker}
          </h3>
          <p style={{ margin: "5px 0", color: "#666" }}>
            {
              markers.find((marker) => marker.name === hoveredMarker)
                ?.description
            }
          </p>
        </div>
      )}
    </div>
  );
}

